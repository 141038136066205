.imageBox {
  position: relative;
  width: 240px;
  aspect-ratio: 8/3;
  span {
    display: block !important;
    width: 100% !important;
    height: 100% !important;
  }
  @media screen and (max-width: 600px) {
    width: 132px;
  }
}
